import React from 'react';

const ShortTopLoader = () => {
  return (
    <div className="bg-loader-short">
      <div className="inside-line-short" />
    </div>
  );
};

export default ShortTopLoader;
